import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// Vueコンポーネントの遅延読み込み
function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}
const router = new Router({
  routes: [
    // メタサイト組み立てページ
    {
      path: '/',
      name: 'TopView',
      component: loadView('TopView')
    },
    // // リアルバーチャル(テンアップ)メタサイト
    // {
    //   path: '/tenup',
    //   name: 'tenup',
    //   component: loadView('TenupView')
    // },
    // リアルバーチャル メタサイト
    {
      path: '/real-virtual',
      name: 'real-virtual',
      component: loadView('RealVirtualView')
    },
    {
      path: '/real-virtual/:page',
      name: 'real-virtual-page',
      component: loadView('RealVirtualView')
    },
    // リアルバーチャル メタサイト イベント入口
    {
      path: '/rv',
      name: 'rv',
      component: loadView('RvView')
    },
    // 花火大会2024 メタサイト
    {
      path: '/rv_fws2024',
      name: 'rv_fws2024',
      component: loadView('RvFws2024View')
    },
    // rv2024 メタサイト
    {
      path: '/rv2024',
      name: 'rv2024',
      component: loadView('Rv2024View')
    },
    // イベント メタサイト
    {
      path: '/event',
      name: 'event',
      component: loadView('EventView')
    },
    // 結婚式場 メタサイト
    {
      path: '/wedding-demo',
      name: 'wedding-demo',
      component: loadView('WeddingDemoView')
    },
    // テスト 天球の中に天球 メタサイト
    {
      path: '/test',
      name: 'test',
      component: loadView('TestView')
    },
    // conferenceサイト
    {
      path: '/confelence',
      name: 'confelence',
      component: loadView('ConfelenceView')
    },
    // 東京農大 北海道オホーツクキャンパス
    {
      path: '/nodai-okhotsk',
      name: 'nodai-okhotsk',
      component: loadView('NodaiOkhotskView')
    },
    {
      path: '/nodai-okhotsk/:page',
      name: 'nodai-okhotsk-page',
      component: loadView('NodaiOkhotskView')
    },
    // 東京農大 メタサイト
    {
      path: '/nodai',
      name: 'nodai',
      component: loadView('NodaiView')
    },
    {
      path: '/nodai/:page',
      name: 'nodai-page',
      component: loadView('NodaiView')
    },
    // ウィルオブワーク メタサイト
    {
      path: '/willof-work',
      name: 'willof-work',
      component: loadView('WillOfWorkView')
    },
    {
      path: '/willof-work/:page',
      name: 'willof-work-page',
      component: loadView('WillOfWorkView')
    },
    // みどりの感謝祭 メタサイト
    // {
    //   path: '/midorinokanshasai',
    //   name: 'midorinokanshasai',
    //   component: loadView('MidorinokanshasaiView')
    // },
    // ギャラクシー スタッフ用 メタサイト
    // {
    //   path: '/galaxy-staff',
    //   name: 'galaxy-staff',
    //   component: loadView('GalaxyStaffView')
    // },
    // {
    //   path: '/galaxy-staff/:page',
    //   name: 'galaxy-staff-page',
    //   component: loadView('GalaxyStaffView')
    // },
    // 明蓬館 メタサイト
    {
      path: '/meihokan',
      name: 'meihokan',
      component: loadView('MeihokanView')
    },
    {
      path: '/meihokan/:page',
      name: 'meihokan-page',
      component: loadView('MeihokanView')
    },
    // アイネス メタサイト
    {
      path: '/ines',
      name: 'ines',
      component: loadView('InesView')
    },
    {
      path: '/ines/:page',
      name: 'ines-page',
      component: loadView('InesView')
    },
    // 三菱総研 メタサイト
    {
      path: '/mri_202407',
      name: 'mri_202407',
      component: loadView('Mri202407View')
    },
    // 学研(バーチャル水族館) メタサイト
    {
      path: '/aquarium-gakken',
      name: 'aquarium-gakken',
      component: loadView('AquariumGakkenView')
    },
    // マリ共和国 メタサイト
    {
      path: '/mali',
      name: 'mali',
      component: loadView('MaliView')
    },
    // 日本大学 メタサイト
    {
      path: '/brs-nihon-u',
      name: 'brs-nihon-u',
      component: loadView('BrsNihonUView')
    },
    // クオリカ メタサイト
    {
      path: '/qualica',
      name: 'qualica',
      component: loadView('QualicaView')
    },
    // MIT メタサイト
    {
      path: '/mit',
      name: 'mit',
      component: loadView('MitView')
    },
    // 代々木ゼミナール メタサイト
    {
      path: '/yozemi',
      name: 'yozemi',
      component: loadView('YozemiView')
    },
    {
      path: '/yozemi/:page',
      name: 'yozemi-page',
      component: loadView('YozemiView')
    },
    // 立命館大学 メタサイト
    {
      path: '/ritsumei',
      name: 'ritsumei',
      component: loadView('RitsumeiView')
    },
    // 成蹊大学 メタサイト
    {
      path: '/seikei',
      name: 'seikei',
      component: loadView('SeikeiView')
    },
    // // メタサイトオリジナルデータ
    // {
    //   path: '/original',
    //   name: 'OriginalView',
    //   component: loadView('OriginalView')
    // },
    {
      path: '*',
      redirect: '/'
    }
  ]
});

export default router;